var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-type-list-container payment-methods mt-5 py-3 rounded-sm",style:({ 'background-color': _vm.options.mainBackgroundColor || 'white' })},[(!_vm.isProfileView)?_c('div',{class:_vm.options.titleStyle
        ? _vm.options.titleStyle
        : 'text-h3 secondary--text mb-5 font-weight-bold text--lighten-1'},[_vm._v(" "+_vm._s(_vm.$t("paymentTypeList.title"))+" ")]):_vm._e(),(!_vm.isProfileView)?_c('i18n',{attrs:{"path":"paymentTypeList.description","tag":"p"}}):_vm._e(),(_vm.isPaymentDisabled)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.$t("paymentTypeList.disabled"))+" ")]):[_c('v-expansion-panels',{attrs:{"accordion":"","flat":_vm.options.flatPanels,"disabled":_vm.isPaymentDisabled},model:{value:(_vm.selectedPayment),callback:function ($$v) {_vm.selectedPayment=$$v},expression:"selectedPayment"}},_vm._l((_vm.paymentTypeList),function(paymentType,index){return _c('v-expansion-panel',{key:paymentType.paymentTypeId,style:({
          'background-color': _vm.options.cardBackgroundColor || 'white'
        }),attrs:{"aria-controls":'panel-content-' + paymentType.paymentTypeId,"role":"button"}},[_c('v-expansion-panel-header',{attrs:{"hide-actions":"","aria-controls":'panel-content-' + paymentType.paymentTypeId,"role":"button","aria-expanded":_vm.selectedPayment === paymentType.paymentTypeId ? 'true' : 'false'},on:{"click":function($event){$event.preventDefault();return _vm.setPaymentInfo(paymentType.name)}}},[_c('input',{attrs:{"type":"radio","aria-label":(paymentType && paymentType.name) || 'Tipo di pagamento',"role":"radio","id":'test' + paymentType.paymentTypeId,"name":"radio-group","data-test":paymentType.gatewayClient},domProps:{"checked":_vm.selectedPayment === index ? true : false}}),_c('label',{staticClass:"payment-method-header-label",attrs:{"for":'test' + paymentType.paymentTypeId}},[(_vm.options.showPaymentMethodImage)?_c('v-img',{staticClass:"mx-3 payment-method-img",attrs:{"src":("/paymentmethod/" + (paymentType.paymentTypeId) + ".svg"),"contain":"","eager":"","onerror":"this.onerror=null;this.src=''","height":"37","max-width":"60","alt":"Metodo di pagamento"}}):_vm._e(),_vm._v(" "+_vm._s(paymentType.name)+" "),_c('v-row',{staticClass:"images-payment-type",class:_vm.$vuetify.breakpoint.xs
                  ? ("mobile images-payment-type-" + (paymentType.paymentTypeId))
                  : ("images-payment-type-" + (paymentType.paymentTypeId)),attrs:{"no-gutters":"","align":"center","justify":_vm.options.cardsPosition || 'end'}},[(
                  _vm.options.showCardLogos &&
                    [10, 12, 22].includes(paymentType.paymentTypeId)
                )?[(_vm.isAmexAccepted)?_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/amex-curved-64px.png","alt":"American Express","height":"37","max-width":"60"}}):_vm._e(),_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/mastercard-curved-64px.png","alt":"Mastercard","height":"37","max-width":"60"}}),_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/electron-curved-64px.png","alt":"Visa Electron","height":"37","max-width":"60"}}),_c('v-img',{attrs:{"eager":"","src":"/credit-card/visa-curved-64px.png","alt":"Visa","height":"37","max-width":"60"}})]:_vm._e(),(
                  _vm.options.showCardLogos &&
                    [13, 27].includes(paymentType.paymentTypeId) &&
                    !_vm.$vuetify.breakpoint.xs
                )?[_c('v-img',{attrs:{"eager":"","src":"/credit-card/paypal-transparent-64px.png","alt":"Paypal","height":"37","max-width":"90"}})]:_vm._e(),(
                  _vm.options.showCardLogos &&
                    [23].includes(paymentType.paymentTypeId)
                )?[_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":("/img_layout/payment_" + (paymentType.paymentTypeId) + ".png"),"alt":"Satispay","height":"37","max-width":"120"}})]:_vm._e()],2)],1)]),_c('v-expansion-panel-content',[_c('div',{class:{ 'payment-disabled': _vm.isPaymentDisabled }},[_c('GatewayWriter',{attrs:{"payment-type":paymentType,"order-id":_vm.orderId,"component-name":paymentType.gatewayClient,"mode":_vm.mode,"options":_vm.mergedOptions,"isProfileView":_vm.isProfileView}})],1)])],1)}),1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }